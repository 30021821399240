export const config = {
    RPC_URL: 'https://rpc-assetmantle-ia.cosmosia.notional.ventures',
    REST_URL: 'https://api-assetmantle-ia.cosmosia.notional.ventures',
    EXPLORER_URL: 'https://www.mintscan.io/asset-mantle',
    STAKING_URL: 'https://assetmantle.omniflix.co/stake',
    NETWORK_NAME: 'Assetmantle',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'mantle-1',
    CHAIN_NAME: 'AssetMantle',
    COIN_DENOM: 'MNTL',
    COIN_MINIMAL_DENOM: 'umntl',
    COIN_DECIMALS: 6,
    PREFIX: 'mantle',
    COIN_TYPE: 118,
    COSMOSTAION: 'assetmantle',
    COINGECKO_ID: 'assetmantle',
    GAS_PRICE_STEP_LOW: 0.01,
    GAS_PRICE_STEP_AVERAGE: 0.025,
    GAS_PRICE_STEP_HIGH: 0.04,
    FEATURES: ['ibc-transfer'],
};
